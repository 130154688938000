var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('div',{staticClass:"q-mx-md q-mb-md text-center q-pt-lg"},[_c('h1',{staticClass:"text-primary"},[_vm._v("Love films?")]),_c('div',{staticClass:"q-mt-md"},[(_vm.is_update)?_c('p',[_vm._v(" Complete your profile to finish the approval process. ")]):_c('p',[_vm._v("Join us and get connected with the likes of you!")])]),_c('div',{staticClass:"row justify-center q-mt-lg"},[_c('div',{staticClass:"col-12 col-sm-6 col-sm-offset-3 q-col-gutter-md"},[_c('q-form',{ref:"submitForm",staticClass:"q-gutter-md",on:{"submit":_vm.attempt_submit}},[_c('q-input',{attrs:{"type":"text","required":"","filled":"","label":"Your Name","rules":[
              function (val) { return (val && val.length > 0) || 'Please fill your name'; } ],"error-message":_vm.signup_error.first_name || _vm.signup_error.last_name,"error":!!(_vm.signup_error.first_name || _vm.signup_error.last_name)},model:{value:(_vm.signup_data.name),callback:function ($$v) {_vm.$set(_vm.signup_data, "name", $$v)},expression:"signup_data.name"}}),_c('q-input',{attrs:{"type":"text","required":"","filled":"","label":"City","hint":"To get your current location, click on the location icon","rules":[
              function (val) { return (val && val.length > 0) || 'Please fill your city'; } ],"error-message":_vm.signup_error.city,"error":!!_vm.signup_error.city},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.getting_location
                    ? 'mdi-loading mdi-spin'
                    : 'mdi-map-marker-outline'},on:{"click":_vm.get_current_location}})]},proxy:true}]),model:{value:(_vm.signup_data.city),callback:function ($$v) {_vm.$set(_vm.signup_data, "city", $$v)},expression:"signup_data.city"}}),_c('q-input',{attrs:{"type":"email","required":"","filled":"","disable":!!_vm.is_update,"autocomplete":"email","label":"Your Email","rules":[
              function (val) { return (val && val.length > 0) || 'Please fill your email'; } ],"error-message":_vm.signup_error.email,"error":!!_vm.signup_error.email || !!_vm.signup_error.username},model:{value:(_vm.signup_data.email),callback:function ($$v) {_vm.$set(_vm.signup_data, "email", $$v)},expression:"signup_data.email"}}),_c('q-input',{attrs:{"type":"tel","required":"","filled":"","mask":"+## ##########","unmasked-value":"","label":"Mobile Number","rules":[function (val) { return !!val || 'Please provide your contact number'; }],"error-message":_vm.signup_error.mobile,"error":!!_vm.signup_error.mobile},model:{value:(_vm.signup_data.mobile),callback:function ($$v) {_vm.$set(_vm.signup_data, "mobile", $$v)},expression:"signup_data.mobile"}}),_c('q-select',{attrs:{"filled":"","transition-show":"scale","transition-hide":"scale","options":_vm.genders,"emit-value":"","map-options":"","label":"Gender","rules":[
              function (val) { return (val && val.length > 0) || 'Please select your gender'; } ],"error-message":_vm.signup_error.gender,"error":!!_vm.signup_error.gender},model:{value:(_vm.signup_data.gender),callback:function ($$v) {_vm.$set(_vm.signup_data, "gender", $$v)},expression:"signup_data.gender"}}),_c('q-input',{attrs:{"filled":"","mask":"####-##-##","label":"Your Birthday","rules":[
              function (val) { return (val && val.length > 0) || 'Please selected your birthday'; },
              function (val) { return _vm.is_valid_date(val) || 'Invalid Date'; },
              function (val) { return _vm.is_above_min_age(val) ||
                'Too early for you to register, wait till you are 13 years old'; } ],"error-message":_vm.signup_error.dob,"error":!!_vm.signup_error.dob},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":"mdi-calendar"}},[_c('q-popup-proxy',{attrs:{"transition-show":"scale","transition-hide":"scale"}},[_c('q-date',{attrs:{"text-color":"dark","mask":"YYYY-MM-DD","navigation-max-year-month":_vm.minus_18_yrs,"default-view":"Calendar"},model:{value:(_vm.signup_data.dob),callback:function ($$v) {_vm.$set(_vm.signup_data, "dob", $$v)},expression:"signup_data.dob"}},[_c('div',{staticClass:"row items-center justify-end"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"ok","color":"primary","flat":""}})],1)])],1)],1)]},proxy:true}]),model:{value:(_vm.signup_data.dob),callback:function ($$v) {_vm.$set(_vm.signup_data, "dob", $$v)},expression:"signup_data.dob"}}),_c('q-input',{attrs:{"label":"Password","filled":"","autocomplete":"new-password","type":"password","rules":[
              function (val) { return (val && val.length > 0) || 'Please enter a password'; },
              function (val) { return val.length > 5 || 'Password should be atleast 6 characters'; } ],"error-message":_vm.signup_error.password,"error":!!_vm.signup_error.password},model:{value:(_vm.signup_data.password),callback:function ($$v) {_vm.$set(_vm.signup_data, "password", $$v)},expression:"signup_data.password"}}),_c('q-input',{attrs:{"label":"Confirm Password","filled":"","autocomplete":"new-password","type":"password","rules":[
              function (val) { return (val && val.length > 0) || 'Please confirm your password'; },
              function (val) { return val === _vm.signup_data.password || 'Your passwords do not match'; } ]},model:{value:(_vm.signup_data.cnf_password),callback:function ($$v) {_vm.$set(_vm.signup_data, "cnf_password", $$v)},expression:"signup_data.cnf_password"}}),_c('div',[_c('q-field',{attrs:{"borderless":"","rules":[
                function (val) { return val == true || 'Please accept terms and conditions'; } ]},model:{value:(_vm.signup_data.accept),callback:function ($$v) {_vm.$set(_vm.signup_data, "accept", $$v)},expression:"signup_data.accept"}},[_c('div',{staticClass:"self-center"},[_c('q-checkbox',{staticClass:"q-mb-0",attrs:{"color":"primary"},model:{value:(_vm.signup_data.accept),callback:function ($$v) {_vm.$set(_vm.signup_data, "accept", $$v)},expression:"signup_data.accept"}}),_vm._v(" I accept the "),_c('router-link',{staticClass:"text-primary",attrs:{"to":{ name: 'tos' },"target":"_blank"}},[_vm._v("terms and conditions")])],1)])],1),_c('div',{staticClass:"text-negative"},[_vm._v(" "+_vm._s(_vm.error_msg)+" ")]),_c('q-btn',{attrs:{"label":"Register","disabled":_vm.loading,"color":"primary","text-color":"dark"},on:{"click":_vm.attempt_submit}}),_c('div',{staticClass:"q-mt-lg"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"text-primary",attrs:{"to":{ name: 'login' }}},[_vm._v("Sign In")])],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }